import $ from 'jquery';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import Headroom from 'headroom.js';
import sal from 'sal.js';
import prettyDropdown from 'pretty-dropdowns';
import MicroModal from 'micromodal';
//Global Vars
let navIsShown = false;
let searchIsShown = false;

const Mangrove = {
  init() {
    this.cache();
    this.events();
    this.initSal();
    this.initHeadroom();
    this.getCurrentPg();
    this.inView();
    this.selectColors();
    this.initMobileDropdowns();
    this.initSmoothScroll();
    this.initStickyImg();
    this.initMicroModal();
    this.closeNavAlts();
    this.rmAuthorLinks();
    this.navActiveFixes();
    if ($("body").hasClass("single-post")) {
      this.blogLinePosition();
    }
    if ($("body").hasClass("single-team-member")) {
      this.showFullBio();
    }
    this.initBlogCategoriesDropdown();
  },
  cache() {
    this.$doc = $(document);
    this.$win = $(window);
    this.$html = $('html');
    this.$body = $('body');
    this.$mainWrap = $('main');
    this.$header = $('.js-header');
    this.$mobileNav = $('.js-mobile-nav');
    this.$hamburger = $('.js-hamburger');
  },
  events() {
    this.$doc.on('click', '.js-hamburger', event => this.showMobileNav(event));
    this.$doc.on('click', '.js-share', event => this.openShareWindow(event));
    this.$doc.on('click', '.js-video-play', event => this.playVideo(event));
    this.$doc.on('click', '.js-show-hide-nav', event => {
      let mainNavWrapper = $('.nav--wrapper');
        event.preventDefault();
        if (!navIsShown) {
          mainNavWrapper.fadeIn();
          $(event.currentTarget).hide();
          $('.nav-link-group--close').css('display', 'block');
          $('header.header').addClass('header--bg-white');
          $('header.header').addClass('header--box-shadow');
          $('.js-show-hide-nav').attr('aria-expanded', 'true');
          $('body').addClass('menu-open');
          navIsShown = true;
        } else {
          mainNavWrapper.fadeOut();
          $(event.currentTarget).hide();
          $('.nav-link-group--open').show();
          $('header.header').removeClass('header--bg-white');
          $('header.header').removeClass('header--box-shadow');
          $('.js-show-hide-nav').attr('aria-expanded', 'false');
          $('body').removeClass('menu-open');
          navIsShown = false;
        }
      },
    );
    this.$doc.on('click', '.js-show-hide-search', event => {
      let mainSearchWrapper = $('.js-search-bar');
        event.preventDefault();
        if (!searchIsShown) {
          mainSearchWrapper.fadeIn();
          $('.js-show-hide-search').attr('aria-expanded', 'true');
          $('body').addClass('search-open');
          searchIsShown = true;
        } else {
          mainSearchWrapper.fadeOut();
          $('.js-show-hide-search').attr('aria-expanded', 'false');
          $('body').removeClass('search-open');
          searchIsShown = false;
        }
      },
    );
    // Execute a function when the user presses a key on the keyboard
    this.$doc.on('keydown', '[js-search-input]', event => {
      // Number 13 is the "Enter" key on the keyboard
      if (event.keyCode === 13) {
        // Cancel the default action, if needed
        event.preventDefault();
        // Trigger the button element with a click
        let searchInputValue = $('[js-search-input]').val();
        let searchURL = '/?s=' + searchInputValue;
        // Redirect user to same page with search query params
        window.location.replace( searchURL );
      }
    });
    this.$doc.on('click', '[js-filter-blog]', event => this.filterBlogByCategoryID(event));
    this.$doc.on('change', '.blog-landing [js-filter-blog-categories-dropdown]', event => this.filterBlogByCategoryIdMobile(event));
    this.$doc.on('click', '.js-clear-category-filter', event => this.clearBlogCategoryFilter(event));
    this.$doc.on('keypress', '.js-clear-category-filter', event => this.clearBlogCategoryFilterKey(event));
    this.$doc.on('click', '.blog-landing [js-paginate]', event => this.blogPaginate(event));
    this.$doc.on('keypress', '.blog-landing [js-paginate]', event => this.blogPaginateKey(event));
    this.$doc.on('change', '.js-tags-check', event => this.tagCheckAlerts(event));
  },
  navActiveFixes() {
    if (window.location.href.indexOf("current_category_ID=39") > -1) {
      this.$body.addClass('events');
    }
  },
  closeNavAlts() {
    $(document).on('keydown', function(event) {
      if ( $('body').hasClass('menu-open') && (event.key == "Escape") ) {
        let mainNavWrapper = $('.nav--wrapper');
        mainNavWrapper.fadeOut();
        $('.nav-link-group--close').hide();
        $('.nav-link-group--open').show();
        $('header.header').removeClass('header--bg-white');
        $('header.header').removeClass('header--box-shadow');
        $('.js-show-hide-nav').attr('aria-expanded', 'false');
        $('body').removeClass('menu-open');
        navIsShown = false;
      }
      if ( $('body').hasClass('search-open') && (event.key == "Escape") ) {
        let mainSearchWrapper = $('.js-search-bar');
        mainSearchWrapper.fadeOut();
        $('.js-show-hide-search').attr('aria-expanded', 'false');
        $('body').removeClass('search-open');
        searchIsShown = false;
      }
    });
    document.addEventListener('focusin', function() {
      if( (!document.activeElement.classList.contains('js-show-hide-nav')) && (document.activeElement.closest(".nav--wrapper") == null) ) {
        let mainNavWrapper = $('.nav--wrapper');
        mainNavWrapper.fadeOut();
        $('.nav-link-group--close').hide();
        $('.nav-link-group--open').show();
        $('header.header').removeClass('header--bg-white');
        $('header.header').removeClass('header--box-shadow');
        $('.js-show-hide-nav').attr('aria-expanded', 'false');
        $('body').removeClass('menu-open');
        navIsShown = false;
      }
      if( (!document.activeElement.classList.contains('js-show-hide-search')) && (document.activeElement.closest(".js-search-bar") == null) ) {
        let mainSearchWrapper = $('.js-search-bar');
        mainSearchWrapper.fadeOut();
        $('.js-show-hide-search').attr('aria-expanded', 'false');
        $('body').removeClass('search-open');
        searchIsShown = false;
      }
    }, true);
  },
  initSal() {
    sal();
  },
  rmAuthorLinks() {
    if ( $('.single-post__author').length ) {
      $('.pp-multiple-authors-layout-inline a').contents().unwrap().wrap('<span/>');
    }
  },
  inView() {
    $.fn.isInViewport = function() {
      var elementTop = $(this).offset().top;
      var elementBottom = elementTop + $(this).outerHeight();
      var viewportTop = $(window).scrollTop();
      var viewportBottom = viewportTop + $(window).height();
      return elementBottom > viewportTop && elementTop < viewportBottom;
    };
    $(window).on('resize scroll', function() {
      $('.js-anim').each(function() {
        if ($(this).isInViewport()) {
          $(this).addClass('visible');
        }
      });
    });
  },
  blogLinePosition() {
    if (screen.width > 1000) {
      var offset = $('.single-post__header').height() + $('.single-post__image').height() + 130;
      $('.single-post__line-anim').css('top', offset);
    }
  },
  initStickyImg() {
    $(window).on('resize scroll', function() {
      if ($(window).width() > 699 && $('.js-sticky').length ) {
        $('.js-sticky').each(function() {
          var el = $(this),
          stickyOffset = el.offset().top - 145,
          stickyEnd = el.find('.js-sticky-end').offset().top,
          sticky = el.find('.text-image__image'),
          width = sticky.width();
          scroll = $(window).scrollTop();

          if (scroll >= stickyOffset) {
            sticky.addClass('fixed');
            el.find('.text-image__image > div').css('width', width);
          }
          else {
            sticky.removeClass('fixed');
          }
          if ( (el.find('.text-image__image > div').offset().top + el.find('.text-image__image > div').height()) >= stickyEnd ) {
            sticky.addClass('stopfixed');
          }
          if ( ((el.find('.text-image__image > div').offset().top) - scroll) >= 145 ) {
            sticky.removeClass('stopfixed');
          }
        });
      }
    });
  },
  selectColors() {
    $('select').on('change', function() {
      if ($(this).val()) {
    return $(this).css('color', '#081f40');
      } else {
    return $(this).css('color', '#5d6770');
      }
    });
  },
  showFullBio() {
    $('.js-bio-swap').click(function() {
      $('.js-bio-full').toggleClass('hidden');
      $('.js-bio').toggleClass('hidden');
      $('html, body').animate({
        scrollTop: $('.single-team-member__bio').not('.hidden').offset().top - 200
      }, 500);
    });
  },
  initSmoothScroll() {
    $(document).ready(function() {
      if(window.location.hash) {
      $('html, body').animate({
          scrollTop: $(window.location.hash).offset().top -150
        }, 500 );
      }
    });
    $('a[href*="#"]')
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function(event) {
      if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
        &&
        location.hostname == this.hostname
      ) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
          if (target.find('.building-block__title').length > 0) {
            var id = target.attr('id');
            event.preventDefault();
            $('html, body').animate({
                scrollTop: $("#"+id+" .building-block__title").offset().top - 150
            }, 500);
          } else {
            event.preventDefault();
            $('html, body').animate({
              scrollTop: target.offset().top - 150
            }, 500 );
          }
        }
      }
    });
  },
  initMobileDropdowns() {
    var toggleDrops = function() {
      $( ".menu-group" ).each(function() {
        var el = $(this)
        if ( el.find('.sub-menu-items__item').length ) {
          el.addClass('menu-item-has-children');
        }
      });
      $('.menu-group.menu-item-has-children').attr('aria-expanded', 'false');
      $('.menu-group.menu-item-has-children').append( '<span class="menu-drop-toggle" role="button" aria-label="toggle submenu"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10"><path fill-rule="evenodd" fill="#ffc42e" d="M8 6.056L2.08.116 0 2.247c.016.022.029.045.047.064C2.487 4.774 7.985 10 7.985 10s4.558-4.284 6.53-6.275c.504-.508 1.003-1.02 1.485-1.51L13.845 0"/></svg></span>');
      $('.menu-group.menu-item-has-children > span').click(function() {
        var item = $(this)
        item.siblings('.sub-menu-items').slideToggle(500);
        item.parent().toggleClass('submenu-active');
        item.parent().attr('aria-expanded',
          item.parent().attr('aria-expanded')=='false' ? 'true' : 'false'
        );
      });
    };
    if ($(window).width() < 1000) {
      toggleDrops();
    }
    $( window ).resize(function() {
      if ($(window).width() > 1000) {
        $('.menu-group.menu-item-has-children').prop('aria-expanded', null );
        $('.sub-menu-items').show();
        $('.menu-drop-toggle').hide();
      } else {
        toggleDrops();
      }
    });
  },
  initMicroModal() {
    MicroModal.init({
      onShow: function () {
        $('html').css('overflow', 'hidden');
      },
      onClose: function() {
        $('html').css('overflow', 'scroll');
      }
    });

    window.closeFiltersModal = () => {
      MicroModal.close('modalTagsFilter');
    }
  },
  openShareWindow(event) {
    let link = $(event.currentTarget).attr('href');
    if (link.substr(0, 6) != 'mailto') {
      event.preventDefault();
      window.open(link, '', 'width=600, height=400');
    }
  },
  playVideo(event) {
    let vid = $(event.currentTarget).next('.js-video')
    vid.trigger('play');
     $(event.currentTarget).addClass('played');
  },
  initHeadroom() {
    let theHeader = document.querySelector('.js-header');
    let headroom = new Headroom(theHeader, { offset: 0 });
    headroom.init();
  },
  getCurrentPg() {
    $('.js-subnav a[href]').filter(function() {
      let thisHref = this.href;
      let locationHref = window.location.href;
      thisHref = thisHref.replace(window.location.protocol + '//', '');
      locationHref = locationHref.replace(window.location.protocol + '//', '');
      return thisHref === locationHref;
    }).addClass('current-page');

    $('.js-subnav a[href]').on('click', (e) => {
      if ($(e.currentTarget).attr('href').includes('/#')) {
        $(e.currentTarget).addClass('current-page');
      } else {
        return;
      }
    })
  },
  initBlogCategoriesDropdown() {
    $('.blog-landing select').prettyDropdown({
      width : 100 + '%',
      height: '45px',
      classic : true,
      customClass : 'dropdown-mobile-category-picker',
      selectedMarker : ''
    });

    handleAriaSelected();

    $('.dropdown-mobile-category-picker').find('select').on('change', () => {
      handleAriaSelected();
    });

    function handleAriaSelected() {
      $('.dropdown-mobile-category-picker').find('li').each( (idx, el) => {
        if($(el).hasClass('selected')) {
          $(el).attr('aria-selected', 'true');
        }else {
          $(el).attr('aria-selected', 'false');
        }
      });
    }
  },
  showMobileNav(event) {
    event.preventDefault();
    this.$mobileNav.toggleClass('is-active');
    this.$hamburger.toggleClass('is-active');
    if (this.$mobileNav.hasClass('is-active')) {
      this.$hamburger.attr('aria-pressed', 'true');
    } else {
      this.$hamburger.removeAttr('aria-pressed');
    }
  },

  blogPaginate(event) {
    let pageNumber = $(event.currentTarget).attr('js-paginate');
    if (pageNumber != false) {
      const thisURLParamsObj = this.getParams(window.location.href);
      this.ajaxLoadBlogPosts(thisURLParamsObj.current_category_ID, pageNumber);
    }
  },

  blogPaginateKey(event) {
    if(event.keyCode == 13) {
      let pageNumber = $(event.currentTarget).attr('js-paginate');
      if (pageNumber != false) {
        const thisURLParamsObj = this.getParams(window.location.href);
        this.ajaxLoadBlogPosts(thisURLParamsObj.current_category_ID, pageNumber);
      }
    }
  },

  filterBlogByCategoryID(event) {
    let thisCategoryID = $(event.currentTarget).attr('data-category-id');
    this.ajaxLoadBlogPosts(thisCategoryID);
    $('.blog-landing .js-clear-category-filter').fadeOut('fast');
    $(event.currentTarget).parent('.blog-nav-catgs__item--wrapper').find('.js-clear-category-filter').fadeIn('fast');
  },
  filterBlogByCategoryIdMobile(event) {
    let chosenCategoryID = $(event.currentTarget).val();
    this.ajaxLoadBlogPosts(chosenCategoryID);
  },
  clearBlogCategoryFilter(event) {
    this.ajaxLoadBlogPosts('');
    $(event.currentTarget).parent('.blog-nav-catgs__item--wrapper').find('.js-clear-category-filter').fadeOut('fast');
  },
  clearBlogCategoryFilterKey(event) {
    if(event.keyCode == 13) {
      this.ajaxLoadBlogPosts('');
      $(event.currentTarget).parent('.blog-nav-catgs__item--wrapper').find('.js-clear-category-filter').fadeOut('fast');
    }
  },
  tagCheckAlerts(event) {
    if( $(event.currentTarget).hasClass('js-tags-all-check') ) {
      if($(event.currentTarget).prop("checked") == true) {
        $('#screenreader-tag-alerts').html('<p role="alert">All tags selected</p>');
      } else {
        $('#screenreader-tag-alerts').html('<p role="alert">All tags unselected</p>');
      }
    } else {
      var totCount = $('.js-tags-list-check').length;
      var selectedCount = $('.js-tags-list-check:checked').length;
      $('#screenreader-tag-alerts').html('<p role="alert">'+selectedCount+' of '+totCount+' tags selected</p>');
    }
  },

  ajaxLoadBlogPosts(categoryID = '', pageNumber = 1) {

    const data = {
      action: 'ajax_blog_filter',
      category_ID : categoryID,
      page_number : pageNumber
    };

    jQuery.ajax({
      url: php_vars.ajax_url,
      type: "POST",
      dataType: 'html',
      data,
      beforeSend : function () {
        window.scrollTo({
          top: 0
        });
        $('.blog-landing').find('.blog-post-cards--wrapper').html('');
        $('.blog-landing').find('.u-ajax-loader').addClass('u-ajax-loader--show');
      },
      success : function (htmlResponse) {
        $('.blog-landing').find('.blog-post-cards--wrapper').html(htmlResponse);
        window.history.replaceState(null, null, `?current_category_ID=${data.category_ID}&page_number=${data.page_number}`);
      },
      complete: function () {
        $('.blog-landing').find('.u-ajax-loader').removeClass('u-ajax-loader--show');
      }
    });
  },
  getParams (url) {
    var params = {};
    var parser = document.createElement('a');
    parser.href = url;
    var query = parser.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
  }
};

Mangrove.init();
